import React from 'react';
import Content from './Content';
import Hero from './Hero';
import styled from '@emotion/styled';
import { color, device } from '../../../constants';
import { Post, HeroContainer, BackgroundBlock } from '../PreviewComponents';

const ContentContainer = styled.div`
  z-index: 2;
  margin-left: 20px;
  margin-right: 20px;
  background-color: ${color.white};

  width: 90%;
  max-width: 90%;

  display: flex;
  justify-content: center;

  @media ${device.mobileS} {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media ${device.mobileM} {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media ${device.tablet} {
    margin: 0px;
    width: 100%;
    max-width: 100%;
  }
`;

export default props => {
  return (
    <Post>
      <BackgroundBlock left={props.left} />
      <HeroContainer>
        <Hero {...props} />
      </HeroContainer>
      <ContentContainer>
        <Content {...props} />
      </ContentContainer>
    </Post>
  );
};
