import { color, device } from '../../constants';
import styled from '@emotion/styled';

export const Hero = styled.div`
  height: 100%;
  max-height: 600px;
  background-color: ${color.baggyGreen};

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    max-height: 927px;
  }

  @media ${device.laptop} {
    max-height: 778px;

    flex-direction: row;
    align-items: normal;
    justify-content: center;
  }
`;

export const Content = styled.div`
  padding: 15px 41px 0px 17px;
  margin-bottom: 0px;
  color: ${color.white};

  @media ${device.tablet} {
    margin-left: -250px;
  }

  @media ${device.laptop} {
    margin-left: -50px;

    max-width: 400px;
    padding: 243px 100px 0px 40px;
  }

  @media ${device.laptopL} {
    margin-left: -250px;
  }
`;

export const Copy = styled.p`
  font-family: 'Open Sans';

  margin: 0px;
  padding-right: 6px;
  font-size: 13px;

  @media ${device.tablet} {
    font-size: 15px;
    max-width: 314px;
  }

  @media ${device.laptop} {
    font-size: 15px;
    margin-top: 33px;
    max-width: 314px;
  }
`;

export const Logo = styled.img`
  margin: 0px;
  max-width: 100px;

  @media ${device.tablet} {
    max-width: 140px;
  }

  @media ${device.laptop} {
    max-width: 236px;
  }
`;

export const WineBottles = styled.img`
  margin-top: 70px;

  width: 100%;
  height: 100%;
  max-width: 246px;
  max-height: 385px;

  @media ${device.tablet} {
    max-width: 449px;
    max-height: 703px;
  }

  @media ${device.laptop} {
    margin: 0px;
    max-width: 554px;
    height: 869px;
    max-height: 869px;

    position: relative;
    bottom: 90px;
  }
`;
