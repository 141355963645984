import React from 'react';
import { Link } from 'gatsby';
import { createSlug } from '../../../../helpers';
import { Content, TypeAndDate, Copy } from '../../PreviewComponents';

export default props => {
  const { title, headline, publishDate } = props;
  return (
    <Content>
      <TypeAndDate date={publishDate} post={true} />
      <Link to={createSlug({ ...props, city: 'post' })}>
        <h3>{title}</h3>
      </Link>
      <Copy className="Body2">{headline}</Copy>
    </Content>
  );
};
