import React from 'react';
import { createSlug } from '../../../../helpers';
import { Hero, Link, Image } from '../../PreviewComponents';

export default props => {
  const { left } = props;

  const image = `${props.hero.file.url}?fit=pad&w=592&h=380`;

  return (
    <Hero>
      <Link to={createSlug({ ...props, city: 'post' })}>
        <Image src={image} alt="Hero" left={left} />
      </Link>
    </Hero>
  );
};
