import _ from 'lodash';
import React from 'react';
import Layout from '../components/Layout';
import Head from '../components/Head';
import Header from '../components/Header';
import CallToAction from '../components/CallToAction';
import Hero from '../components/Hero';
import PostList from '../components/PostList';

const schema = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Seventyfive CL',
  url: 'https://seventyfive.cl',
  description: 'Home to the best wine bars in London.',
  alternateName: ['75cl'],
  sameAs: ['https://www.instagram.com/seventyfivecl'],
};

export default props => {
  const {
    analyticsId,
    citiesList,
    defaultCity,
    wineBars,
    posts,
  } = props.pageContext;

  const allPreviews = [
    ...wineBars.map(wineBar => ({ ...wineBar, type: 'WINEBAR' })),
    ...posts.map(post => ({ ...post, type: 'POST' })),
  ];

  const filteredPreviews = _(allPreviews)
    .orderBy(['publishDate'], 'desc')
    .filter(({ city }) => (city === 'all' ? true : city === defaultCity))
    .value();

  const title = _.startCase(_.toLower(defaultCity));

  return (
    <Layout>
      <Head title={title} schema={schema} description={schema.description} />
      <Header
        citiesList={citiesList}
        defaultCity={defaultCity}
        analyticsId={analyticsId}
      />
      <Hero />
      <CallToAction />
      <PostList previews={filteredPreviews} />
    </Layout>
  );
};
