import * as gatsby from 'gatsby';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import React from 'react';
import { color, fontWeight, device } from '../../../constants';
import dropPin from '../../../assets/dropPin.png';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    max-width: 320px;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 24px;
  }

  a {
    text-decoration: none;
    font-weight: ${fontWeight.bold};
  }

  @media ${device.tablet} {
    h3 {
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 32px;
    }
  }

  @media ${device.laptopL} {
    h3 {
      max-width: 400px;
      margin-top: 9px;
      margin-bottom: 9px;
      font-size: 48px;
    }
  }
`;

export const TypeAndDate = ({ date, post }) => {
  const formattedDate = dayjs(date).format('DD MMMM, YYYY');

  const title = post ? 'Post' : 'Review';

  return (
    <p className="Subtext1-Bold">
      {title} &nbsp; <span className="Subtext1">{formattedDate}</span>
    </p>
  );
};

export const Copy = styled.div`
  max-width: 300px;

  font-size: 15px;
`;

export const DropPin = () => {
  const Image = styled.img`
    width: 11px;
    height: 15.8px;
  `;

  return <Image src={dropPin} alt="DropPin" />;
};

export const Location = styled.div`
  margin-top: 21px;
  margin-bottom: 36px;

  @media ${device.tablet} {
    margin-top: 43px;
  }

  @media ${device.laptopL} {
    margin-top: 79px;
  }
`;

export const Post = styled.div`
  margin-top: 50px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  :nth-of-type(n + 2) {
    margin-top: 100px;
  }

  :last-of-type {
    margin-bottom: 20px;
  }

  @media ${device.tablet} {
    :nth-of-type(odd) {
      margin-top: 200px;
      flex-direction: row;
    }

    :nth-of-type(even) {
      margin-top: 200px;
      flex-direction: row-reverse;
    }

    :first-of-type {
      margin-top: 100px;
    }

    :last-of-type {
      margin-bottom: 100px;
    }
  }

  @media ${device.laptopL} {
    :nth-of-type(odd) {
      margin-top: 250px;
    }

    :nth-of-type(even) {
      margin-top: 250px;
    }

    :first-of-type {
      margin-top: 150px;
    }

    :last-of-type {
      margin-bottom: 200px;
    }
  }
`;

export const Hero = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const Link = styled(gatsby.Link)`
  all: unset;

  display: grid;

  cursor: pointer;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;

  @media ${device.tablet} {
    max-width: 342px;
    max-height: 220px;

    margin-left: ${props => (props.left ? '0px' : '30px')};
    margin-right: ${props => (props.left ? '30px' : '0px')};
  }

  @media ${device.laptop} {
    max-width: 420px;
    max-height: 269px;

    margin-left: ${props => (props.left ? '0px' : '50px')};
    margin-right: ${props => (props.left ? '50px' : '0px')};
  }

  @media ${device.laptopL} {
    max-width: 592px;
    max-height: 380px;

    margin-left: ${props => (props.left ? '0px' : '80px')};
    margin-right: ${props => (props.left ? '80px' : '0px')};
  }
`;

export const BackgroundBlock = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
    background-color: ${color.white};

    position: absolute;
    z-index: 1;

    margin-top: 0px;
    margin-left: ${props => (props.left ? '150px' : '-150px')};

    @-moz-document url-prefix() {
      margin-left: ${props => (props.left ? '270px' : '-270px')};
    }

    height: 100%;
    width: 100%;
    max-height: 384px;
    max-width: 425px;
  }

  @media ${device.laptop} {
    max-width: 507px;
    max-height: 384px;

    margin-left: ${props => (props.left ? '177px' : '-177px')};

    @-moz-document url-prefix() {
      margin-left: ${props => (props.left ? '283px' : '-283px')};
    }
  }

  @media ${device.laptopL} {
    max-width: 716px;
    max-height: 540px;

    margin-left: ${props => (props.left ? '250px' : '-250px')};

    @-moz-document url-prefix() {
      margin-left: ${props => (props.left ? '400px' : '-400px')};
    }
  }
`;

export const HeroContainer = styled.div`
  z-index: 2;

  height: 100%;
  width: 100%;
`;
