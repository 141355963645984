import React from 'react';
import { Link } from 'gatsby';
import { createSlug } from '../../../../helpers';
import {
  Content,
  TypeAndDate,
  Copy,
  DropPin,
  Location,
} from '../../PreviewComponents';

export default props => {
  const { title, headline, suburb, publishDate } = props;
  return (
    <Content>
      <TypeAndDate date={publishDate} />
      <Link to={createSlug(props)}>
        <h3>{title}</h3>
      </Link>
      <Copy className="Body2">{headline}</Copy>
      <Location className="CallToAction2">
        <DropPin />
        &nbsp; {suburb}
      </Location>
    </Content>
  );
};
