import React from 'react';
import styled from '@emotion/styled';
import { createSlug } from '../../../../helpers';
import { color, device } from '../../../../constants';
import { Hero, Link, Image } from '../../PreviewComponents';

const Rating = styled.div`
  position: absolute;
  top: -33px;
  left: 0%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${color.white};

  font-size: 32px;

  width: 67px;
  height: 67px;

  @media ${device.tablet} {
    top: -35px;
    left: ${props => (props.left ? '-30px' : '90%')};
  }

  @media ${device.laptopL} {
    top: -35px;
    left: ${props => (props.left ? '-35px' : '95%')};
  }
`;

export default props => {
  const { left, rating } = props;
  const image = `${props.hero.file.url}?fit=pad&w=592&h=380`;

  return (
    <Hero>
      <Rating className="SubHeading2" left={left}>
        {rating}
      </Rating>
      <Link to={createSlug(props)}>
        <Image src={image} alt="Hero" left={left} />
      </Link>
    </Hero>
  );
};
