import logo from '../../assets/logo.svg';
import hero from '../../assets/hero.png';
import React from 'react';
import { Hero, Content, Logo, Copy, WineBottles } from './components';

export default () => (
  <Hero>
    <Content>
      <Logo src={logo} alt="Logo" />
      <Copy>
        A library of the best wine bars and none
        <br /> of the bullshit. For people who love wine.
      </Copy>
    </Content>
    <WineBottles src={hero} alt="Hero" />
  </Hero>
);
