import React from 'react';
import styled from '@emotion/styled';
import { color, device } from '../../constants';

export const Container = styled.div`
  display: flex;

  max-width: 330px;
  height: 60px;
  margin: 20px;

  background-color: ${color.white};
  border-radius: 5px;

  @media ${device.laptop} {
    max-width: 330px;
    margin: 0px;
  }
`;

export const Input = styled.input`
  font-weight: 300;

  font-size: 18px;
  outline: none;
  border: none;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;

  width: 65%;
  max-width: 65%;
  margin-right: 10px;

  @media ${device.laptop} {
    font-size: 22px;
  }
`;

export const Button = styled.button`
  width: 35%;
  max-width: 35%;

  font-size: 18px;

  border-radius: 5px;

  background-color: ${color.baggyGreen};
  color: ${color.white};

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
`;

const SpinnerContainer = styled.svg`
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;

  outline: none;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const Circle = styled.circle`
  stroke: ${color.lightPink};
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;

  outline: none;
  fill: none;
  stroke-width: 4;
`;

export const Spinner = () => (
  <SpinnerContainer>
    <Circle cx="25" cy="25" r="20" />
  </SpinnerContainer>
);
