import React from 'react';
import styled from '@emotion/styled';
import { color, device, fontWeight } from '../../constants';
import Newsletter from './Newsletter';
import zigZag from '../../assets/zigzag.svg';

const CallToAction = styled.div`
  min-height: 600px;
  background-color: ${color.cream2};

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  align-items: center;

  .Copy {
    font-family: 'Begum';
    font-size: 32px;
    text-align: center;

    margin: 0px;
    max-width: 334px;
  }

  .ZigZag {
    width: 14px;
    height: 93px;
  }

  .Top {
    margin-top: 20px;
  }

  @media ${device.tablet} {
    .Copy {
      max-width: 696px;
    }
  }
`;

export default () => (
  <CallToAction>
    <img className="ZigZag Top" src={zigZag} alt="Zigzag" />
    <p className="SubHeading2 Copy">
      75cl is founded by three Australian expats seeking to build a library of
      quality wine bars & stores around the globe.
    </p>
    <Newsletter />
    <img className="ZigZag" src={zigZag} alt="Zigzag" />
  </CallToAction>
);
