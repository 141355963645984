import Axios from 'axios';
import React, { useState } from 'react';
import * as Components from './Components';

const signUp = async email => {
  if (!email) return;
  await Axios.post('/.netlify/functions/createContact', { email });
};

export default () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  return (
    <Components.Container>
      <Components.Input
        onChange={event => setEmail(event.target.value)}
        name="email"
        placeholder="Email"
      />
      <Components.Button
        onClick={async () => {
          await setLoading(true);
          await signUp(email);
          await setLoading(false);
        }}
      >
        {loading ? <Components.Spinner /> : 'Subscribe'}
      </Components.Button>
    </Components.Container>
  );
};
