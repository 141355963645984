import { color } from '../../constants';
import PostPreview from './PostPreview';
import WineBarPreview from './WineBarPreview';
import React from 'react';
import styled from '@emotion/styled';
const { createSlug } = require('../../helpers');

const isLeft = number => number % 2 === 0;

const PostList = styled.div`
  background-color: ${color.cream2};

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  align-items: center;
`;

const createKey = post => `post${createSlug(post)}`;

const createPreview = previews =>
  previews.map((preview, key) =>
    preview.type === 'POST' ? (
      <PostPreview key={createKey(preview)} {...preview} left={isLeft(key)} />
    ) : (
      <WineBarPreview
        key={createKey(preview)}
        {...preview}
        left={isLeft(key)}
      />
    ),
  );

export default ({ previews }) => {
  if (!previews) return undefined;
  return <PostList>{createPreview(previews)}</PostList>;
};
